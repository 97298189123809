import axios from 'axios';

import * as urls from 'constants/urls';
import { omit } from 'ramda';

const getRelations = (params = {}, relations) => params.disableRelations ? [] : relations;

export const postLoginHandler = ({ payload }) => axios.post(urls.LOGIN.stringify(), payload);
export const postRefreshTokenHandler = ({ payload }) => axios.post(urls.REFRESH_TOKEN.stringify(), {
    refresh_token: payload
});

export const postRecoveryHandler = ({ payload }) => axios.post(urls.RECOVERY.stringify(), payload);
export const getRecoveryHandler = ({ payload }) => axios.get(urls.RECOVERY.stringify({ id: payload }));
export const postResetHandler = ({ payload: { token, password }}) => axios.post(urls.RECOVERY.stringify({ id: token }), { password });

export const getUserHandler = ({ params }) => axios.get(urls.GET_USER.stringify(), {
    params: {
        relations: getRelations(params, ['shops', 'shops.network', 'shops.region', 'supervisor', 'promoters', 'region', 'photo', 'city'])
    }
});
export const putRestoreUserUserHandler = ({ payload }) => axios.put(urls.RESTORE_USER.stringify({ id: payload }), {});

const getShopParams = (payload, params, relations = ['promoters', 'supervisors']) => ({
    params: {
        relations: getRelations(params, ['region', 'city', 'network', 'city.region', ...relations]),
        ...payload
    }
});

export const getShopsHandler = ({ payload }) => axios.get(urls.SHOPS.stringify(), getShopParams(payload));
export const getShopHandler = ({ payload }) => axios.get(urls.SHOPS.stringify({ id: payload.id }), getShopParams(payload));
export const postShopHandler = ({ payload }) => axios.post(urls.SHOPS.stringify(), payload);
export const putShopHandler = ({ payload }) => axios.put(urls.SHOPS.stringify({ id: payload.id }), payload);
export const deleteShopHandler = ({ payload }) => axios.delete(urls.SHOPS.stringify({ id: payload }), payload);

export const getNetworksHandler = ({ payload }) => axios.get(urls.NETWORKS.stringify(), { params: payload });
export const postNetworkHandler = ({ payload }) => axios.post(urls.NETWORKS.stringify(), payload);
export const putNetworkHandler = ({ payload }) => axios.put(urls.NETWORKS.stringify({ id: payload.id }), payload);
export const deleteNetworkHandler = ({ payload }) => axios.delete(urls.NETWORKS.stringify({ id: payload }), payload);

export const getRegionsHandler = ({ payload }) => axios.get(urls.REGIONS.stringify(), { params: payload });
export const postRegionHandler = ({ payload }) => axios.post(urls.REGIONS.stringify(), payload);
export const putRegionHandler = ({ payload }) => axios.put(urls.REGIONS.stringify({ id: payload.id }), payload);
export const deleteRegionHandler = ({ payload }) => axios.delete(urls.REGIONS.stringify({ id: payload }), payload);

export const getCitiesHandler = ({ payload, params }) => axios.get(urls.CITIES.stringify(), {
    params: {
        relations: getRelations(params, ['region']),
        ...payload
    }
});
export const postCityHandler = ({ payload }) => axios.post(urls.CITIES.stringify(), payload);
export const putCityHandler = ({ payload }) => axios.put(urls.CITIES.stringify({ id: payload.id }), payload);
export const deleteCityHandler = ({ payload }) => axios.delete(urls.CITIES.stringify({ id: payload }), payload);

export const getProductsListHandler = ({ payload, params }) => axios.get(urls.PRODUCTS.stringify(), {
    params: {
        relations: getRelations(params, ['productCategory', 'productGroup', 'productDepartment', 'brand']),
        ...payload
    }
});

export const postProductHandler = ({ payload }) => axios.post(urls.PRODUCTS.stringify(), payload);
export const putProductHandler = ({ payload }) => axios.put(urls.PRODUCTS.stringify({ id: payload.id }), payload);
export const deleteProductHandler = ({ payload }) => axios.delete(urls.PRODUCTS.stringify({ id: payload }));

export const getProductCategoriesHandler = ({ payload, params }) => axios.get(urls.PRODUCT_CATEGORIES.stringify(), {
    params: {
        relations: getRelations(params, ['productGroup', 'productDepartment']),
        ...payload
    }
});

export const postProductCategoryHandler = ({ payload }) => axios.post(urls.PRODUCT_CATEGORIES.stringify(), payload);
export const putProductCategoryHandler = ({ payload }) => axios.put(urls.PRODUCT_CATEGORIES.stringify({ id: payload.id }), payload);
export const deleteProductCategoryHandler = ({ payload }) => axios.delete(urls.PRODUCT_CATEGORIES.stringify({ id: payload }));

export const getProductGroupsHandler = ({ payload, params }) => axios.get(urls.PRODUCT_GROUPS.stringify(), {
    params: {
        relations: getRelations(params, ['productDepartment']),
        ...payload
    }
});

export const postProductGroupHandler = ({ payload }) => axios.post(urls.PRODUCT_GROUPS.stringify(), payload);
export const putProductGroupHandler = ({ payload }) => axios.put(urls.PRODUCT_GROUPS.stringify({ id: payload.id }), payload);
export const deleteProductGroupHandler = ({ payload }) => axios.delete(urls.PRODUCT_GROUPS.stringify({ id: payload }));

export const getProductDepartmentsHandler = ({ payload }) => axios.get(urls.PRODUCT_DEPARTMENTS.stringify(), {
    params: payload
});

export const postProductDepartmentHandler = ({ payload }) => axios.post(urls.PRODUCT_DEPARTMENTS.stringify(), payload);
export const putProductDepartmentHandler = ({ payload }) => axios.put(urls.PRODUCT_DEPARTMENTS.stringify({ id: payload.id }), payload);
export const deleteProductDepartmentHandler = ({ payload }) => axios.delete(urls.PRODUCT_DEPARTMENTS.stringify({ id: payload }));

export const getBrandsHandler = ({ payload }) => axios.get(urls.BRANDS.stringify(), { params: payload });
export const postBrandHandler = ({ payload }) => axios.post(urls.BRANDS.stringify(), payload);
export const putBrandHandler = ({ payload }) => axios.put(urls.BRANDS.stringify({ id: payload.id }), payload);
export const deleteBrandHandler = ({ payload }) => axios.delete(urls.BRANDS.stringify({ id: payload }));

export const getClientsListHandler = ({ payload }) => axios.get(urls.CLIENTS.stringify(), {
    params: payload
});

export const postClientHandler = ({ payload }) => axios.post(urls.CLIENTS.stringify(), payload);
export const putClientHandler = ({ payload, params }) => axios.put(urls.CLIENTS.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo']) }
});
export const getClientHandler = ({ payload }) => axios.get(urls.CLIENTS.stringify({ id: payload.id }), {
    params: { relations: ['photo'] }
});

export const getAdminsListHandler = ({ payload }) => axios.get(urls.ADMINS.stringify(), {
    params: payload
});

export const postAdminHandler = ({ payload }) => axios.post(urls.ADMINS.stringify(), payload);
export const putAdminHandler = ({ payload, params }) => axios.put(urls.ADMINS.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo']) }
});
export const getAdminHandler = ({ payload }) => axios.get(urls.ADMINS.stringify({ id: payload.id }), {
    params: { relations: ['photo'] }
});

export const getGradesHandler = ({ payload }) => axios.get(urls.GRADES.stringify(), {
    params: payload
});
export const postGradeHandler = ({ payload }) => axios.post(urls.GRADES.stringify(), payload);
export const putGradeHandler = ({ payload, params }) => axios.put(urls.GRADES.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo']) }
});
export const getGradeHandler = ({ payload }) => axios.get(urls.GRADES.stringify({ id: payload.id }), {
    params: { relations: ['photo'] }
});
export const deleteGradeHandler = ({ payload }) => axios.delete(urls.GRADES.stringify({ id: payload }));
export const putGradeRestoreHandler = ({ payload }) => axios.put(urls.GRADES_RESTORE.stringify({ id: payload }));

export const getMarketingCoordinatorsListHandler = ({ payload, params }) => axios.get(urls.MARKETING_COORDINATORS.stringify(), {
    params: {
        relations: getRelations(params, ['regions']),
        ...payload
    }
});

export const postMarketingCoordinatorHandler = ({ payload }) => axios.post(urls.MARKETING_COORDINATORS.stringify(), payload);
export const putMarketingCoordinatorHandler = ({ payload, params }) => axios.put(urls.MARKETING_COORDINATORS.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo', 'regions']) }
});
export const getMarketingCoordinatorHandler = ({ payload, params }) => axios.get(urls.MARKETING_COORDINATORS.stringify({ id: payload.id }), {
    params: { relations: getRelations(params, ['regions', 'photo']) }
});

export const getSupervisorsListHandler = ({ payload, params }) => axios.get(urls.SUPERVISORS.stringify(), {
    params: {
        relations: getRelations(params, ['region', 'city', 'grade']),
        ...payload
    }
});

export const postSupervisorHandler = ({ payload }) => axios.post(urls.SUPERVISORS.stringify(), payload);
export const putSupervisorHandler = ({ payload, params }) => axios.put(urls.SUPERVISORS.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo', 'region', 'city', 'grade']) }
});
export const getSupervisorHandler = ({ payload, params }) => axios.get(urls.SUPERVISORS.stringify({ id: payload.id }), {
    params: { relations: getRelations(params, ['promoters', 'shops', 'shops.network', 'shops.network', 'shops.city', 'photo', 'region', 'city', 'grade']) }
});

export const getPromotersListHandler = ({ payload, params }) => axios.get(urls.PROMOTERS.stringify(), {
    params: {
        relations: getRelations(params, ['region', 'city', 'grade']),
        ...payload
    }
});

export const postPromoterHandler = ({ payload }) => axios.post(urls.PROMOTERS.stringify(), payload);
export const putPromoterHandler = ({ payload, params }) => axios.put(urls.PROMOTERS.stringify({ id: payload.id }), payload, {
    params: { relations: getRelations(params, ['photo', 'region', 'city', 'grade']) }
});
export const getPromoterHandler = ({ payload, params }) => axios.get(urls.PROMOTERS.stringify({ id: payload.id }), {
    params: { relations: getRelations(params, ['shops', 'shops.network', 'shops.network', 'shops.city', 'supervisor', 'photo', 'region', 'city', 'grade']) }
});

export const postSaleHandler = ({ payload }) => axios.post(urls.SALES.stringify(), payload);
export const putSaleHandler = ({ payload }) => axios.put(urls.SALES.stringify({ id: payload.id }), payload);
export const deleteSaleHandler = ({ payload }) => axios.delete(urls.SALES.stringify({ id: payload }));
export const postSalesHandler = ({ payload }) => {
    return axios.all(payload.products.map(product => (
        axios.post(urls.SALES.stringify(), {
            ...omit(['products'], payload),
            product: product.id,
            quantity: product.quantity,
            price: product.price,
        })
    )));
};
export const getSalesListHandler = ({ payload, params }) => axios.get(urls.SALES.stringify(), {
    params: {
        relations: getRelations(params, ['productCategory', 'productGroup', 'productDepartment', 'promoter', 'product', 'city', 'shop']),
        ...payload
    }
});
export const deleteSalesHandler = ({ payload }) => axios.all(payload.map(id => (
    axios.delete(urls.SALES.stringify({ id }))
)));

export const getTasksHandler = ({ payload, params }) => axios.get(urls.TASKS.stringify(), {
    params: {
        relations: getRelations(params, ['region', 'shop', 'city', 'network']),
        ...payload
    }
});
export const postTaskHandler = ({ payload }) => axios.post(urls.TASKS.stringify(), payload);
export const getTaskHandler = ({ payload, params }) => axios.get(urls.TASKS.stringify({ id: payload }), {
    params: {
        relations: getRelations(params, ['region', 'shop', 'city', 'network', 'files'])
    }
});
export const putTaskHandler = ({ payload }) => axios.put(urls.TASKS.stringify({ id: payload.id }), payload);

export const getTaskReportsHandler = ({ payload, params }) => axios.get(urls.TASK_REPORTS.stringify(), {
    params: {
        relations: getRelations(params, ['shop', 'network', 'city', 'region', 'promoter', 'files', 'task', 'task.shop', 'task.network', 'task.city', 'task.files']),
        ...payload
    }
});
export const getTaskReportHandler = ({ payload, params }) => axios.get(urls.TASK_REPORTS.stringify({ id: payload }), {
    params: {
        relations: getRelations(params, ['task', 'task.shop', 'task.city', 'task.network', 'task.files', 'shop', 'files'])
    }
});
export const postTaskReportHandler = ({ payload }) => axios.post(urls.TASK_REPORTS.stringify(), payload);

export const postCheckinHandler = ({ payload }) => axios.post(urls.CHECKIN.stringify(), payload);
export const putCheckinHandler = ({ payload }) => axios.put(urls.CHECKIN.stringify({ id: payload.id }), payload)
export const getCheckinsHandler = ({ payload, params }) => axios.get(urls.CHECKIN.stringify(), {
    params: {
        relations: getRelations(params, ['shop', 'supervisor', 'promoter', 'promoter.city']),
        ...payload
    }
});
export const getCurrentCheckinHandler = ({ params }) => axios.get(urls.CURRENT_CHECKIN.stringify(), {
    params: { relations: getRelations(params, ['shop', 'shop.network']) }
});

export const getSalePlansHandler = ({ payload, params }) => axios.get(urls.SALE_PLANS.stringify(), {
    params: {
        relations: getRelations(params, ['departmentPlans.productDepartment', 'network', 'region', 'shop', 'supervisor', 'supervisor.shops', 'promoter', 'productDepartment']),
        ...payload
    }
});
export const postSalePlansHandler = ({ payload }) => axios.post(urls.SALE_PLANS.stringify(), payload);
export const putSalePlansHandler = ({ payload }) => axios.put(urls.SALE_PLANS.stringify({ id: payload.id }), payload);
export const deleteSalePlansHandler = ({ payload }) => axios.delete(urls.SALE_PLANS.stringify({ id: payload }));

export const getTimetableHandler = ({ payload, params }) => axios.get(urls.TIMETABLE.stringify(), {
    params: {
        relations: getRelations(params, ['promoter', 'promoter.supervisor', 'promoter.shops']),
        ...payload
    }
});
export const putTimetableHandler = ({ payload }) => axios.put(urls.TIMETABLE.stringify({ id: payload.id }), payload);
export const getTimetableMonthsHandler = ({ payload, params }) => axios.get(urls.TIMETABLE_MONTHS.stringify(), {
    params: {
        relations: getRelations(params, ['employee', 'employee.city', 'employee.grade', 'supervisors', 'shops', 'shops.network', 'reports', 'reports.dayReports', 'statistic']),
        ...payload
    }
});
export const getTimetableSyncStatusHandler = ({ payload }) => axios.get(urls.TIMETABLE_SYNC_STATUS.stringify(), {
    params: {
        q: payload,
    }
});
export const putTimetableSyncHandler = ({ payload }) => axios.put(urls.TIMETABLE_SYNC.stringify({ date: payload }), {}, {
    params: { force: 1 }
});
export const getHolydaysCalendarHandler = ({ payload }) => axios.get(urls.CALENDAR.stringify(), {
    params: {
        q: payload,
    }
});

export const getNewsListHandler = ({ payload }) => axios.get(urls.NEWS.stringify(), { params: payload });
export const getNewsHandler = ({ payload }) => axios.get(urls.NEWS.stringify({ id: payload.id }), { params: payload });
export const postNewsHandler = ({ payload }) => axios.post(urls.NEWS.stringify(), payload);
export const putNewsHandler = ({ payload }) => axios.put(urls.NEWS.stringify({ id: payload.id }),  payload);
export const deleteNewsHandler = ({ payload }) => axios.delete(urls.NEWS.stringify({ id: payload }));

export const getDocumentsHandler = ({ payload }) => axios.get(urls.DOCUMENTS.stringify(), { params: payload });
export const postDocumentHandler = ({ payload }) => axios.post(urls.DOCUMENTS.stringify(), payload);
export const deleteDocumentHandler = ({ payload }) => axios.delete(urls.DOCUMENTS.stringify({ id: payload }));

export const postInstructionHandler = ({ payload }) => axios.post(urls.INSTRUCTION.stringify(), payload);
export const putInstructionHandler = ({ payload }) => axios.put(urls.INSTRUCTION.stringify({ id: payload.id }), payload);
export const deleteInstructionHandler = ({ payload }) => axios.delete(urls.INSTRUCTION.stringify({ id: payload }));
export const getInstructionsHandler = ({ payload, params }) => axios.get(urls.INSTRUCTION.stringify(), {
    params: {
        relations: getRelations(params, ['shop', 'promoter', 'supervisor', 'employee']),
        ...payload
    }
});

export const postInstructionsHandler = ({ payload }) =>
    axios.all(payload.items.map(item => axios.post(urls.INSTRUCTION.stringify(), { ...item, date: payload.date })));

export const deleteFileHandler = ({ payload }) => axios.delete(urls.FILE.stringify({ id: payload }));
export const postFileHandler = ({ payload }) => axios.post(urls.FILE.stringify(), payload);

export const importSalePlansHandler = ({ payload }) => axios.post(urls.SALE_PLANS_IMPORT.stringify({ date: payload.date }), payload.file);
export const importProductsHandler = ({ payload }) => axios.post(urls.PRODUCTS_IMPORT.stringify(), payload.file);
export const importShopsHandler = ({ payload }) => axios.post(urls.SHOPS_IMPORT.stringify(), payload.file);
export const importCitiesHandler = ({ payload }) => axios.post(urls.CITIES_IMPORT.stringify(), payload.file);
export const importClientsHandler = ({ payload }) => axios.post(urls.CLIENTS_IMPORT.stringify(), payload.file);
export const importMarketingCoordinatorsHandler = ({ payload }) => axios.post(urls.MARKETING_COORDINATOR_IMPORT.stringify(), payload.file);
export const importSupervisorsHandler = ({ payload }) => axios.post(urls.SUPERVISOR_IMPORT.stringify(), payload.file);
export const importPromotersHandler = ({ payload }) => axios.post(urls.PROMOTER_IMPORT.stringify(), payload.file);
export const importSalesHandler = ({ payload }) => axios.post(urls.SALES_IMPORT.stringify(), payload.file);
export const importPlansHandler = ({ payload }) => axios.post(urls.PLANS_IMPORT.stringify({ date: payload.date }), payload.file);
export const importGradesHandler = ({ payload }) => axios.post(urls.GRADES_IMPORT.stringify(), payload.file);
export const importTimetableHandler = ({ payload }) => axios.post(urls.TIMETABLE_IMPORT.stringify({ date: payload.date }), payload.file);

export const postUserPhotoHandler = ({ payload, params }) => axios.post(urls.USER_PHOTO.stringify({ id: params }), payload);
export const postClientPhotoHandler = ({ payload, params }) => axios.post(urls.CLIENT_PHOTO.stringify({ id: params }), payload);
export const postMarketingCoordinatorPhotoHandler = ({ payload, params }) => axios.post(urls.MARKETING_COORDINATOR_PHOTO.stringify({ id: params }), payload);
export const postSupervisorPhotoHandler = ({ payload, params }) => axios.post(urls.SUPERVISOR_PHOTO.stringify({ id: params }), payload);
export const postPromoterPhotoHandler = ({ payload, params }) => axios.post(urls.PROMOTER_PHOTO.stringify({ id: params }), payload);
export const postAdminPhotoHandler = ({ payload, params }) => axios.post(urls.ADMIN_PHOTO.stringify({ id: params }), payload);

export const deleteUserPhotoHandler = ({ payload }) => axios.delete(urls.USER_PHOTO.stringify({ id: payload }));
export const deleteClientPhotoHandler = ({ payload }) => axios.delete(urls.CLIENT_PHOTO.stringify({ id: payload }));
export const deleteMarketingCoordinatorPhotoHandler = ({ payload }) => axios.delete(urls.MARKETING_COORDINATOR_PHOTO.stringify({ id: payload }));
export const deleteSupervisorPhotoHandler = ({ payload }) => axios.delete(urls.SUPERVISOR_PHOTO.stringify({ id: payload }));
export const deletePromoterPhotoHandler = ({ payload }) => axios.delete(urls.PROMOTER_PHOTO.stringify({ id: payload }));
export const deleteAdminPhotoHandler = ({ payload }) => axios.delete(urls.ADMIN_PHOTO.stringify({ id: payload }));

export const getSalePlanDepartmentsHandler = ({ payload }) => axios.get(urls.SALE_PLAN_DEPARTMENTS.stringify({ date: payload }), {
    params: {
        relations: ['departmentPlans.productGroups', 'departmentPlans.productDepartment'],
        limit: 0
    }
});
export const putSalePlanDepartmentsHandler = ({ payload }) => axios.put(urls.SALE_PLAN_DEPARTMENTS.stringify({ date: payload.date }), payload, {
    params: {
        relations: ['departmentPlans.productGroups', 'departmentPlans.productDepartment']
    }
});
export const deleteSalePlanDepartmentsHandler = ({ payload }) => axios.delete(urls.SALE_PLAN_DEPARTMENTS.stringify({ date: payload }));

export const getSalePlansStatHandler = ({ payload, params }) => axios.get(urls.SALE_PLANS_STAT.stringify(), {
    params: {
        relations: getRelations(params, ['departmentPlans.productDepartment', 'network', 'region', 'shop', 'supervisor', 'supervisor.shops', 'promoter', 'productDepartment']),
        ...payload
    }
});

export const getSalePlansPromoterStatusHandler = ({ payload, params }) => axios.get(urls.SALE_PLANS_PROMOTER_STATUS.stringify(), {
    params: payload
});

export const getSalesSettingsHandler = ({ payload }) => axios.get(urls.SALES_SETTINGS.stringify(), {
    params: payload
});
export const postSalesSettingsHandler = ({ payload }) => axios.post(urls.SALES_SETTINGS.stringify(), payload);
export const putSalesSettingsHandler = ({ payload }) => axios.put(urls.SALES_SETTINGS.stringify({ id: payload.id }), payload);
export const deleteSalesSettingsHandler = ({ payload }) => axios.delete(urls.SALES_SETTINGS.stringify({ id: payload }));

export const putProductsRestoreHandler = ({ payload }) => axios.put(urls.PRODUCTS_RESTORE.stringify({ id: payload }));
