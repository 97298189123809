import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map, pick } from 'ramda';

import { closeModal, hideModal, showModal } from 'actions/modalActions';
import { mapIndexed } from 'utils/ramdaAdditions';

import {
    OPEN_PRODUCT_MODAL,
    OPEN_PRODUCT_CATEGORY_MODAL,
    OPEN_PRODUCT_GROUP_MODAL,
    OPEN_PRODUCT_DEPARTMENT_MODAL,
    OPEN_SHOP_MODAL,
    OPEN_NETWORK_MODAL,
    OPEN_CLIENT_MODAL,
    OPEN_MARKETING_COORDINATOR_MODAL,
    OPEN_SUPERVISOR_MODAL,
    OPEN_PROMOTER_MODAL,
    OPEN_SALE_EDIT_MODAL,
    OPEN_SALES_PRODUCT_MODAL,
    OPEN_CHECKIN_MODAL,
    OPEN_CITY_MODAL,
    OPEN_REGION_MODAL,
    OPEN_COORDINATES_LOADER,
    OPEN_TIMETABLE_MODAL,
    OPEN_TIMETABLE_SYNC_MODAL,
    OPEN_TIMETABLE_PERSON_SHOPS_MODAL,
    OPEN_NEWS_MODAL,
    OPEN_DOCUMENT_MODAL,
    OPEN_DOCUMENT_ATTACH_MODAL,
    OPEN_PROMOTER_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_PROMOTER_BIND_MODAL,
    OPEN_SUPERVISOR_SHOP_BIND_MODAL,
    OPEN_PROMOTER_SHOP_BIND_MODAL,
    OPEN_DISMISSAL_MODAL,
    OPEN_UNBIND_MODAL,
    OPEN_PREVIEW_MODAL,
    OPEN_IMPORT_MODAL,
    OPEN_CROP_IMAGE_MODAL,
    OPEN_SHOP_MAP_MODAL,
    OPEN_BRAND_MODAL,
    OPEN_MAP_MODAL,
    OPEN_ADMIN_MODAL,
    OPEN_GRADE_MODAL,
    OPEN_PLAN_PROMOTER_MODAL,
    OPEN_FOCUS_CATEGORIES_PROJECT_MODAL,
    OPEN_FOCUS_CATEGORIES_COPY_MODAL,
    OPEN_SALES_SETTINGS_MODAL,
    OPEN_PLAN_COMMENT_MODAL
} from 'constants/actionTypes';

import ProductModal from './products/ProductModal';
import ProductCategoryModal from './products/CategoryModal';
import ProductGroupModal from './products/GroupModal';
import ProductDepartmentModal from './products/DepartmentModal';
import ShopModal from './shops/ShopModal';
import NetworkModal from './shops/NetworkModal';
import ClientModal from './users/ClientModal';
import MarketingCoordinatorModal from './users/MarketingCoordinatorModal';
import SupervisorModal from './users/SupervisorModal';
import PromoterModal from './users/PromoterModal';
import SaleEditModal from './sales/SaleEditModal';
import SalesProductModal from './sales/SalesProductModal';
import CheckinModal from './CheckinModal';
import CityModal from './geography/CityModal';
import RegionModal from './geography/RegionModal';
import CoordinatesLoader from './CoordinatesLoader';
import TimetableModal from './TimetableModal';
import TimetableSyncModal from './timetable/TimetableSyncModal';
import TimetablePersonShopsModal from './timetable/TimetablePersonShopsModal';
import NewsModal from './news/NewsModal';
import DocumentModal from './documents/DocumentModal';
import DocumentAttachModal from './documents/DocumentAttachModal';
import PromoterSupervisorBindModal from './binds/modals/PromoterSupervisorBindModal';
import ShopSupervisorBindModal from './binds/modals/ShopSupervisorBindModal';
import ShopPromoterBindModal from './binds/modals/ShopPromoterBindModal';
import SupervisorShopBindModal from './binds/modals/SupervisorShopBindModal';
import PromoterShopBindModal from './binds/modals/PromoterShopBindModal';
import DismissalModal from './users/DismissalModal';
import UnbindModal from './binds/UnbindModal';
import PreviewModal from './PreviewModal';
import ImportModal from './ImportModal';
import CropImageModal from './CropImageModal';
import ShopMapModal from './maps/ShopMapModal';
import MapModal from './maps/MapModal';
import BrandModal from './products/BrandModal';
import AdminModal from './users/AdminModal';
import GradeModal from './users/GradeModal';
import PlanPromoterModal from './plans/PlanPromoterModal';
import FocusCategoriesProjectModal from './plans/FocusCategoriesProjectModal';
import FocusCategoriesCopyModal from './plans/FocusCategoriesCopyModal';
import SalesSettingsModal from './sales/SalesSettingsModal';
import PlanCommentModal from './plans/PlanCommentModal';

const stateToProps = pick(['modals']);

const modalComponents = {
    [OPEN_PRODUCT_MODAL]: ProductModal,
    [OPEN_PRODUCT_CATEGORY_MODAL]: ProductCategoryModal,
    [OPEN_PRODUCT_GROUP_MODAL]: ProductGroupModal,
    [OPEN_PRODUCT_DEPARTMENT_MODAL]: ProductDepartmentModal,
    [OPEN_SHOP_MODAL]: ShopModal,
    [OPEN_NETWORK_MODAL]: NetworkModal,
    [OPEN_CLIENT_MODAL]: ClientModal,
    [OPEN_MARKETING_COORDINATOR_MODAL]: MarketingCoordinatorModal,
    [OPEN_SUPERVISOR_MODAL]: SupervisorModal,
    [OPEN_PROMOTER_MODAL]: PromoterModal,
    [OPEN_SALE_EDIT_MODAL]: SaleEditModal,
    [OPEN_SALES_PRODUCT_MODAL]: SalesProductModal,
    [OPEN_CHECKIN_MODAL]: CheckinModal,
    [OPEN_CITY_MODAL]: CityModal,
    [OPEN_REGION_MODAL]: RegionModal,
    [OPEN_COORDINATES_LOADER]: CoordinatesLoader,
    [OPEN_TIMETABLE_MODAL]: TimetableModal,
    [OPEN_TIMETABLE_SYNC_MODAL]: TimetableSyncModal,
    [OPEN_TIMETABLE_PERSON_SHOPS_MODAL]: TimetablePersonShopsModal,
    [OPEN_NEWS_MODAL]: NewsModal,
    [OPEN_DOCUMENT_MODAL]: DocumentModal,
    [OPEN_PROMOTER_SUPERVISOR_BIND_MODAL]: PromoterSupervisorBindModal,
    [OPEN_SHOP_SUPERVISOR_BIND_MODAL]: ShopSupervisorBindModal,
    [OPEN_SHOP_PROMOTER_BIND_MODAL]: ShopPromoterBindModal,
    [OPEN_DISMISSAL_MODAL]: DismissalModal,
    [OPEN_UNBIND_MODAL]: UnbindModal,
    [OPEN_PREVIEW_MODAL]: PreviewModal,
    [OPEN_IMPORT_MODAL]: ImportModal,
    [OPEN_CROP_IMAGE_MODAL]: CropImageModal,
    [OPEN_SHOP_MAP_MODAL]: ShopMapModal,
    [OPEN_SUPERVISOR_SHOP_BIND_MODAL]: SupervisorShopBindModal,
    [OPEN_PROMOTER_SHOP_BIND_MODAL]: PromoterShopBindModal,
    [OPEN_BRAND_MODAL]: BrandModal,
    [OPEN_MAP_MODAL]: MapModal,
    [OPEN_ADMIN_MODAL]: AdminModal,
    [OPEN_GRADE_MODAL]: GradeModal,
    [OPEN_PLAN_PROMOTER_MODAL]: PlanPromoterModal,
    [OPEN_FOCUS_CATEGORIES_PROJECT_MODAL]: FocusCategoriesProjectModal,
    [OPEN_FOCUS_CATEGORIES_COPY_MODAL]: FocusCategoriesCopyModal,
    [OPEN_SALES_SETTINGS_MODAL]: SalesSettingsModal,
    [OPEN_PLAN_COMMENT_MODAL]: PlanCommentModal,
    [OPEN_DOCUMENT_ATTACH_MODAL]: DocumentAttachModal,
};

@connect(stateToProps, { close: closeModal, hide: hideModal, show: showModal })
export default class Modals extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        show: PropTypes.func.isRequired,
        modals: PropTypes.array.isRequired
    };

    getModalComponent = ({ type, params, visible }, index, length) => {
        const actions = map(action => action.bind(null, index), pick(['close', 'hide', 'show'], this.props));
        const ModalComponent = modalComponents[type];
        const modalProps = {
            visible: index + 1 === length && visible,
            width: 1000,
            onCancel: actions.close,
            maskClosable: false
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    render() {
        const { modals } = this.props;

        return (
            <div>
                {mapIndexed((modal, index) => this.getModalComponent(modal, index, modals.length), modals)}
            </div>
        );
    }
}
