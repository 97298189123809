import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { trim } from 'ramda';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class TextComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
    };

    onChange = e => {
        this.props.onChange(e.target.value || '');
    }

    onBlur = () => this.props.onChange(trim(this.props.value || ''));

    render() {
        const { value, placeholder } = this.props;

        return (
            <Input.TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                value={value}
                placeholder={placeholder}
                onChange={this.onChange}
                onBlur={this.onBlur} />
        );
    }
}

export default withFormikField(TextComponent);

