import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '';

export const LOGIN = construct('/login_check');
export const REFRESH_TOKEN = construct('/refresh_token');
export const RECOVERY = construct('/password_recovery(/:id)');
export const GET_USER = construct('/sessions/current');
export const RESTORE_USER = construct('/users/:id/restore');
export const USER_PHOTO = construct('/sessions/current/photo');
export const SHOPS = construct('/shops(/:id)');
export const SHOPS_IMPORT = construct('/shops/import');
export const NETWORKS = construct('/networks(/:id)');
export const REGIONS = construct('/regions(/:id)');
export const CITIES = construct('/cities(/:id)');
export const CITIES_IMPORT = construct('/cities/import');
export const PRODUCTS = construct('/products(/:id)');
export const PRODUCTS_IMPORT = construct('/products/import');
export const PRODUCT_CATEGORIES = construct('/product_categories(/:id)');
export const PRODUCT_GROUPS = construct('/product_groups(/:id)');
export const PRODUCT_DEPARTMENTS = construct('/product_departments(/:id)');
export const BRANDS = construct('/brands(/:id)');
export const CLIENTS = construct('/clients(/:id)');
export const CLIENT_PHOTO = construct('/clients/:id/photo');
export const CLIENTS_IMPORT = construct('/clients/import');
export const MARKETING_COORDINATORS = construct('/marketing_coordinators(/:id)');
export const MARKETING_COORDINATOR_PHOTO = construct('/marketing_coordinators/:id/photo');
export const MARKETING_COORDINATOR_IMPORT = construct('/marketing_coordinators/import');
export const SUPERVISORS = construct('/supervisors(/:id)');
export const SUPERVISOR_PHOTO = construct('/supervisors/:id/photo');
export const SUPERVISOR_IMPORT = construct('/supervisors/import');
export const PROMOTERS = construct('/promoters(/:id)');
export const PROMOTER_PHOTO = construct('/promoters/:id/photo');
export const PROMOTER_IMPORT = construct('/promoters/import');
export const SALES = construct('/sales(/:id)');
export const CHECKIN = construct('/checkins(/:id)');
export const CURRENT_CHECKIN = construct('/checkins/current');
export const TASKS = construct('/tasks(/:id)');
export const SALE_PLANS_STAT = construct('/sale_plans/stat');
export const SALE_PLANS_IMPORT = construct('/sale_plans/import(/:date)');
export const CALENDAR = construct('/calendar');
export const TIMETABLE = construct('/month_reports(/:id)');
export const TIMETABLE_MONTHS = construct('/month_reports/table(/:id)');
export const TIMETABLE_SYNC_STATUS = construct('/month_reports/sync/status');
export const TIMETABLE_SYNC = construct('/month_reports/sync(/:date)');
export const TIMETABLE_IMPORT = construct('/month_reports/import(/:date)');
export const TASK_REPORTS = construct('/task_reports(/:id)');
export const NEWS = construct('/news(/:id)');
export const DOCUMENTS = construct('/documents(/:id)');
export const FILE = construct('/files(/:id)');
export const FILE_DOWNLOAD = construct('/files/:id/download');
export const INSTRUCTION = construct('/instructions(/:id)');
export const PHOTO = construct('/files/:id/view');
export const GRADES = construct('/grades(/:id)');
export const GRADES_RESTORE = construct('/grades/:id/restore');
export const ADMINS = construct('/admins(/:id)');
export const ADMIN_PHOTO = construct('/admins/:id/photo');
export const SALE_PLANS = construct('/sale_plans(/:id)');
export const SALE_PLAN_DEPARTMENTS = construct('/sale_plan_departments(/:date)');
export const SALE_PLANS_PROMOTER_STATUS = construct('/sale_plans/promoter/status');
export const GRADES_IMPORT = construct('/grades/import');
export const SALES_IMPORT = construct('/sales/import');
export const PLANS_IMPORT = construct('/sale_plans/import/:date');
export const SALES_SETTINGS = construct('/settings/sales(/:id)');
export const PRODUCTS_RESTORE = construct('/products/:id/restore');
