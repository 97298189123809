import React from 'react';
import { ShopOutlined } from '@ant-design/icons';

const ShopName = ({ shop = {} }) => {
    const { name } = shop;
    const { code } = shop;

    return name ? (
        <span className='shop-element'>
            <ShopOutlined />
            {name}<br/>
            {code &&
                <small><i>{code}</i></small>
            }
        </span>
    ) : null;
}

export default ShopName;
