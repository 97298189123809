import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { Button, Row, Col } from 'antd';
import { isEmpty, pathOr } from 'ramda';
import { LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import Fab from 'components/common/Fab';
import Spinner from 'components/common/Spinner';
import CheckinTimeInfo from 'components/common/cards/checkin/CheckinTimeInfo';
import CheckinTimeSpent from 'components/common/cards/checkin/CheckinTimeSpent';
import { openCoordinatesLoader } from 'actions/modalActions';
import { getCurrentCheckin, putCheckin } from 'actions/asyncActions';
import { fullAutoOptions, formAutoOptions } from 'constants/asyncClient';
import ShopCheckinPlans from './ShopCheckinPlans';
import CheckinTasks from './CheckinTasks';

@connect(null, { openCoordinatesLoader })
@withAsyncActions({
    getCurrentCheckin: getCurrentCheckin
        .withOptions(fullAutoOptions),
    putCheckin: putCheckin
        .withSuccessHandler(({ history }) => history.push('/visits'))
        .withOptions(formAutoOptions)
})
export default class Checkin extends Component {
    finishCheckin = coordinates => {
        const { getCurrentCheckin: { data }, putCheckin } = this.props;
        const date = moment.utc().toDate();

        putCheckin.dispatch({ ...data, out: { date, coordinates: isEmpty(coordinates) ? null : coordinates }, status: 'finished' });
    }

    renderCheckinInfo = () => {
        const { openCoordinatesLoader, getCurrentCheckin: { data, meta } } = this.props;
        const active = data.status !== 'finished';

        return <div className='layout-wrap'>
            <ShopCheckinPlans checkin={data} />
            { active ? (
                <Row>
                    <Col xs={24} lg={12}>
                        <Button
                            style={{ marginBottom: 16, marginTop: 16 }}
                            block
                            type='primary'
                            size='large'
                            onClick={() => openCoordinatesLoader({
                                onSuccess: this.finishCheckin,
                                shopCoords: pathOr({}, ['_embedded', 'shop', 'coordinates'], data),
                                checkout: true
                            })}>
                            <span><LogoutOutlined /> Выйти из чекина</span>
                        </Button>
                    </Col>
                </Row>
            ) : meta.success && (
                <Row style={{ marginBottom: 16, marginTop: 16 }}>
                    <Col xs={24}>
                        <h3>Чекин закрыт</h3>
                    </Col>
                    <Col xs={24}>
                        <Row>
                            <Col xs={12} md={6}><b>Время захода</b></Col>
                            <Col xs={12} md={6}><b>Время выхода</b></Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <CheckinTimeInfo checkin={data} />
                            </Col>
                            <Col xs={12} md={6}>
                                <CheckinTimeInfo checkin={data} out={true} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row>
                            <Col xs={12} md={6}><b>Затраченное время</b></Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <CheckinTimeSpent checkin={data} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            <CheckinTasks checkin={data} />
            { active &&
                <Fab
                    icon={<PlusOutlined />}
                    onClick={() => this.props.history.push(`/sales/add/${ data.date }`)} />
            }
        </div>;
    }

    render() {
        const { pending } = this.props.getCurrentCheckin.meta;
        const { id } = this.props.getCurrentCheckin.data;

        if (pending) {
            return <Spinner />;
        }

        return id ? this.renderCheckinInfo() : <div className='text-center no-active-checkin'>Нет активных чекинов</div>;
    }
}
