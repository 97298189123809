export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const POST_RECOVERY = 'POST_RECOVERY';
export const GET_RECOVERY = 'GET_RECOVERY';
export const POST_RESET = 'POST_RESET';
export const GET_USER = 'GET_USER';
export const PUT_RESTORE_USER = 'PUT_RESTORE_USER';
export const APP_RUN = 'APP_RUN';
export const POST_REFRESH_TOKEN = 'POST_REFRESH_TOKEN';

export const GET_SHOPS = 'GET_SHOPS';
export const GET_SHOP = 'GET_SHOP';
export const POST_SHOP = 'POST_SHOP';
export const PUT_SHOP = 'PUT_SHOP';
export const DELETE_SHOP = 'DELETE_SHOP';

export const GET_NETWORKS = 'GET_NETWORKS';
export const POST_NETWORK = 'POST_NETWORK';
export const PUT_NETWORK = 'PUT_NETWORK';
export const DELETE_NETWORK = 'DELETE_NETWORK';

export const GET_REGIONS = 'GET_REGIONS';
export const POST_REGIONS = 'POST_REGIONS';
export const PUT_REGIONS = 'PUT_REGIONS';
export const DELETE_REGIONS = 'DELETE_REGIONS';

export const GET_CITIES = 'GET_CITIES';
export const POST_CITIES = 'POST_CITIES';
export const PUT_CITIES = 'PUT_CITIES';
export const DELETE_CITIES = 'DELETE_CITIES';

export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const POST_PRODUCT = 'POST_PRODUCT';
export const PUT_PRODUCT = 'PUT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export const GET_PRODUCT_CATEGORIES = 'GET_CATEGORIES';
export const POST_PRODUCT_CATEGORY = 'POST_PRODUCT_CATEGORY';
export const PUT_PRODUCT_CATEGORY = 'PUT_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY';

export const GET_PRODUCT_GROUPS = 'GET_GROUPS';
export const POST_PRODUCT_GROUP = 'POST_PRODUCT_GROUP';
export const PUT_PRODUCT_GROUP = 'PUT_PRODUCT_GROUP';
export const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP';

export const GET_PRODUCT_DEPARTMENTS = 'GET_DEPARTMENTS';
export const POST_PRODUCT_DEPARTMENT = 'POST_PRODUCT_DEPARTMENT';
export const PUT_PRODUCT_DEPARTMENT = 'PUT_PRODUCT_DEPARTMENT';
export const DELETE_PRODUCT_DEPARTMENT = 'DELETE_PRODUCT_DEPARTMENT';

export const GET_BRANDS = 'GET_BRANDS';
export const POST_BRANDS = 'POST_BRANDS';
export const PUT_BRANDS = 'PUT_BRANDS';
export const DELETE_BRANDS = 'DELETE_BRANDS';

export const GET_SALES_LIST = 'GET_SALES_LIST';
export const POST_SALE = 'POST_SALE';
export const DELETE_SALE = 'DELETE_SALE';
export const PUT_SALE = 'PUT_SALE';
export const POST_SALES = 'POST_SALES';
export const DELETE_SALES = 'DELETE_SALES';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

export const OPEN_PRODUCT_MODAL = 'OPEN_PRODUCT_MODAL';
export const OPEN_PRODUCT_CATEGORY_MODAL = 'OPEN_PRODUCT_CATEGORY_MODAL';
export const OPEN_PRODUCT_GROUP_MODAL = 'OPEN_PRODUCT_GROUP_MODAL';
export const OPEN_PRODUCT_DEPARTMENT_MODAL = 'OPEN_PRODUCT_DEPARTMENT_MODAL';
export const OPEN_SHOP_MODAL = 'OPEN_SHOP_MODAL';
export const OPEN_NETWORK_MODAL = 'OPEN_NETWORK_MODAL';
export const OPEN_CLIENT_MODAL = 'OPEN_CLIENT_MODAL';
export const OPEN_MARKETING_COORDINATOR_MODAL = 'OPEN_MARKETING_COORDINATOR_MODAL';
export const OPEN_SUPERVISOR_MODAL = 'OPEN_SUPERVISOR_MODAL';
export const OPEN_PROMOTER_MODAL = 'OPEN_PROMOTER_MODAL';
export const OPEN_SALE_EDIT_MODAL = 'OPEN_SALE_EDIT_MODAL';
export const OPEN_SALES_PRODUCT_MODAL = 'OPEN_SALES_PRODUCT_MODAL';
export const OPEN_CHECKIN_MODAL = 'OPEN_CHECKIN_MODAL';
export const OPEN_CITY_MODAL = 'OPEN_CITY_MODAL';
export const OPEN_REGION_MODAL = 'OPEN_REGION_MODAL';
export const OPEN_COORDINATES_LOADER = 'OPEN_COORDINATES_LOADER';
export const OPEN_TIMETABLE_MODAL = 'OPEN_TIMETABLE_MODAL';
export const OPEN_TIMETABLE_SYNC_MODAL = 'OPEN_TIMETABLE_SYNC_MODAL';
export const OPEN_TIMETABLE_PERSON_SHOPS_MODAL = 'OPEN_TIMETABLE_PERSON_SHOPS_MODAL';
export const OPEN_NEWS_MODAL = 'OPEN_NEWS_MODAL';
export const OPEN_DOCUMENT_MODAL = 'OPEN_DOCUMENT_MODAL';
export const OPEN_DOCUMENT_ATTACH_MODAL = 'OPEN_DOCUMENT_ATTACH_MODAL';
export const OPEN_PROMOTER_SUPERVISOR_BIND_MODAL = 'OPEN_PROMOTER_SUPERVISOR_BIND_MODAL';
export const OPEN_SHOP_SUPERVISOR_BIND_MODAL = 'OPEN_SHOP_SUPERVISOR_BIND_MODAL';
export const OPEN_SHOP_PROMOTER_BIND_MODAL = 'OPEN_SHOP_PROMOTER_BIND_MODAL';
export const OPEN_DISMISSAL_MODAL = 'OPEN_DISMISSAL_MODAL';
export const OPEN_UNBIND_MODAL = 'OPEN_UNBIND_MODAL';
export const OPEN_PREVIEW_MODAL = 'OPEN_PREVIEW_MODAL';
export const OPEN_IMPORT_MODAL = 'OPEN_IMPORT_MODAL';
export const OPEN_CROP_IMAGE_MODAL = 'OPEN_CROP_IMAGE_MODAL';
export const OPEN_SHOP_MAP_MODAL = 'OPEN_SHOP_MAP_MODAL';
export const OPEN_SUPERVISOR_SHOP_BIND_MODAL = 'OPEN_SUPERVISOR_SHOP_BIND_MODAL';
export const OPEN_PROMOTER_SHOP_BIND_MODAL = 'OPEN_PROMOTER_SHOP_BIND_MODAL';
export const OPEN_BRAND_MODAL = 'OPEN_BRAND_MODAL';
export const OPEN_MAP_MODAL = 'OPEN_MAP_MODAL';
export const OPEN_ADMIN_MODAL = 'OPEN_ADMIN_MODAL';
export const OPEN_GRADE_MODAL = 'OPEN_GRADE_MODAL';
export const OPEN_PLAN_PROMOTER_MODAL = 'OPEN_PLAN_PROMOTER_MODAL';
export const OPEN_FOCUS_CATEGORIES_PROJECT_MODAL = 'OPEN_FOCUS_CATEGORIES_PROJECT_MODAL';
export const OPEN_FOCUS_CATEGORIES_COPY_MODAL = 'OPEN_FOCUS_CATEGORIES_COPY_MODAL';
export const OPEN_SALES_SETTINGS_MODAL = 'OPEN_SALES_SETTINGS_MODAL';
export const OPEN_PLAN_COMMENT_MODAL = 'OPEN_PLAN_COMMENT_MODAL';

export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const POST_CLIENT = 'POST_CLIENT';
export const PUT_CLIENT = 'PUT_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';

export const GET_ADMINS_LIST = 'GET_ADMINS_LIST';
export const POST_ADMIN = 'POST_ADMIN';
export const PUT_ADMIN = 'PUT_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';

export const GET_GRADES = 'GET_GRADES';
export const GET_GRADE = 'GET_GRADE';
export const POST_GRADE = 'POST_GRADE';
export const PUT_GRADE = 'PUT_GRADE';
export const PUT_GRADE_RESTORE = 'PUT_GRADE_RESTORE';
export const DELETE_GRADE = 'DELETE_GRADE';

export const GET_MARKETING_COORDINATORS_LIST = 'GET_MARKETING_COORDINATORS_LIST';
export const POST_MARKETING_COORDINATOR = 'POST_MARKETING_COORDINATOR';
export const PUT_MARKETING_COORDINATOR = 'PUT_MARKETING_COORDINATOR';
export const GET_MARKETING_COORDINATOR = 'GET_MARKETING_COORDINATOR';

export const GET_SUPERVISORS_LIST = 'GET_SUPERVISORS_LIST';
export const POST_SUPERVISOR = 'POST_SUPERVISOR';
export const PUT_SUPERVISOR = 'PUT_SUPERVISOR';
export const GET_SUPERVISOR = 'GET_SUPERVISOR';

export const GET_PROMOTERS_LIST = 'GET_PROMOTERS_LIST';
export const POST_PROMOTER = 'POST_PROMOTER';
export const PUT_PROMOTER = 'PUT_PROMOTER';
export const GET_PROMOTER = 'GET_PROMOTER';

export const POST_CHECKIN = 'POST_CHECKIN';
export const GET_CHECKINS = 'GET_CHECKINS';
export const GET_CURRENT_CHECKIN = 'GET_CURRENT_CHECKIN';
export const PUT_CHECKIN = 'PUT_CHECKIN';

export const GET_TASKS = 'GET_TASKS';
export const POST_TASK = 'POST_TASK';
export const GET_TASK = 'GET_TASK';
export const PUT_TASK = 'PUT_TASK';

export const GET_TASK_REPORTS = 'GET_TASK_REPORTS';
export const GET_TASK_REPORT = 'GET_TASK_REPORT';
export const POST_TASK_REPORT = 'POST_TASK_REPORT';

export const GET_SALE_PLANS = 'GET_SALE_PLANS';
export const POST_SALE_PLANS = 'POST_SALE_PLANS';
export const PUT_SALE_PLANS = 'PUT_SALE_PLANS';
export const PUT_SALE_PLANS_WITHOUT_REFRESH = 'PUT_SALE_PLANS_WITHOUT_REFRESH';
export const DELETE_SALE_PLANS = 'DELETE_SALE_PLANS';
export const GET_SALE_PLANS_STAT = 'GET_SALE_PLANS_STAT';

export const GET_TIMETABLE = 'GET_TIMETABLE';
export const GET_HOLYDAYS_CALENDAR = 'GET_HOLYDAYS_CALENDAR';
export const GET_TIMETABLE_MONTHS = 'GET_TIMETABLE_MONTHS';
export const PUT_TIMETABLE = 'PUT_TIMETABLE';
export const GET_TIMETABLE_SYNC_STATUS = 'GET_TIMETABLE_SYNC_STATUS';
export const PUT_TIMETABLE_SYNC = 'PUT_TIMETABLE_SYNC';

export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_NEWS = 'GET_NEWS';
export const POST_NEWS = 'POST_NEWS';
export const PUT_NEWS = 'PUT_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const POST_DOCUMENT = 'POST_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const POST_INSTRUCTION = 'POST_INSTRUCTION';
export const GET_INSTRUCTIONS = 'GET_INSTRUCTIONS';
export const PUT_INSTRUCTION = 'PUT_INSTRUCTION';
export const POST_INSTRUCTIONS = 'POST_INSTRUCTIONS';
export const DELETE_INSTRUCTION = 'DELETE_INSTRUCTION';

export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';

export const IMPORT_SALE_PLANS = 'IMPORT_SALE_PLANS';
export const IMPORT_SHOPS = 'IMPORT_SHOPS';
export const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';
export const IMPORT_CITIES = 'IMPORT_CITIES';
export const IMPORT_CLIENTS = 'IMPORT_CLIENTS';
export const IMPORT_MARKETING_COORDINATORS = 'IMPORT_MARKETING_COORDINATORS';
export const IMPORT_SUPERVISORS = 'IMPORT_SUPERVISORS';
export const IMPORT_PROMOTERS = 'IMPORT_PROMOTERS';
export const IMPORT_SALES = 'IMPORT_SALES';
export const IMPORT_PLANS = 'IMPORT_PLANS';
export const IMPORT_TIMETABLE = 'IMPORT_TIMETABLE';
export const IMPORT_GRADES = 'IMPORT_GRADES';

export const POST_USER_PHOTO = 'POST_USER_PHOTO';
export const POST_CLIENT_PHOTO = 'POST_CLIENT_PHOTO';
export const POST_MARKETING_COORDINATOR_PHOTO = 'POST_MARKETING_COORDINATOR_PHOTO';
export const POST_SUPERVISOR_PHOTO = 'POST_SUPERVISOR_PHOTO';
export const POST_PROMOTER_PHOTO = 'POST_PROMOTER_PHOTO';
export const POST_ADMIN_PHOTO = 'POST_ADMIN_PHOTO';

export const DELETE_USER_PHOTO = 'DELETE_USER_PHOTO';
export const DELETE_CLIENT_PHOTO = 'DELETE_CLIENT_PHOTO';
export const DELETE_MARKETING_COORDINATOR_PHOTO = 'DELETE_MARKETING_COORDINATOR_PHOTO';
export const DELETE_SUPERVISOR_PHOTO = 'DELETE_SUPERVISOR_PHOTO';
export const DELETE_PROMOTER_PHOTO = 'DELETE_PROMOTER_PHOTO';
export const DELETE_ADMIN_PHOTO = 'DELETE_ADMIN_PHOTO';

export const GET_SALE_PLAN_DEPARTMENTS = 'GET_SALE_PLAN_DEPARTMENTS';
export const PUT_SALE_PLAN_DEPARTMENTS = 'PUT_SALE_PLAN_DEPARTMENTS';
export const DELETE_SALE_PLAN_DEPARTMENTS = 'DELETE_SALE_PLAN_DEPARTMENTS';
export const GET_SALE_PLANS_PROMOTER_STATUS = 'GET_SALE_PLANS_PROMOTER_STATUS';

export const GET_SALES_SETTINGS = 'GET_SALES_SETTINGS';
export const POST_SALES_SETTINGS = 'POST_SALES_SETTINGS';
export const PUT_SALES_SETTINGS = 'PUT_SALES_SETTINGS';
export const DELETE_SALES_SETTINGS = 'DELETE_SALES_SETTINGS';

export const PUT_PRODUCTS_RESTORE = 'PUT_PRODUCTS_RESTORE';
