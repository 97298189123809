import { createAction } from 'react-async-client';

import {
    CLOSE_MODAL,
    SHOW_MODAL,
    HIDE_MODAL,
    OPEN_PRODUCT_MODAL,
    OPEN_PRODUCT_CATEGORY_MODAL,
    OPEN_PRODUCT_GROUP_MODAL,
    OPEN_PRODUCT_DEPARTMENT_MODAL,
    OPEN_SHOP_MODAL,
    OPEN_NETWORK_MODAL,
    OPEN_CLIENT_MODAL,
    OPEN_MARKETING_COORDINATOR_MODAL,
    OPEN_SUPERVISOR_MODAL,
    OPEN_PROMOTER_MODAL,
    OPEN_SALE_EDIT_MODAL,
    OPEN_SALES_PRODUCT_MODAL,
    OPEN_CHECKIN_MODAL,
    OPEN_CITY_MODAL,
    OPEN_REGION_MODAL,
    OPEN_COORDINATES_LOADER,
    OPEN_TIMETABLE_MODAL,
    OPEN_TIMETABLE_SYNC_MODAL,
    OPEN_TIMETABLE_PERSON_SHOPS_MODAL,
    OPEN_NEWS_MODAL,
    OPEN_DOCUMENT_MODAL,
    OPEN_DOCUMENT_ATTACH_MODAL,
    OPEN_PROMOTER_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_PROMOTER_BIND_MODAL,
    OPEN_SUPERVISOR_SHOP_BIND_MODAL,
    OPEN_PROMOTER_SHOP_BIND_MODAL,
    OPEN_DISMISSAL_MODAL,
    OPEN_UNBIND_MODAL,
    OPEN_PREVIEW_MODAL,
    OPEN_IMPORT_MODAL,
    OPEN_CROP_IMAGE_MODAL,
    OPEN_SHOP_MAP_MODAL,
    OPEN_BRAND_MODAL,
    OPEN_MAP_MODAL,
    OPEN_ADMIN_MODAL,
    OPEN_GRADE_MODAL,
    OPEN_PLAN_PROMOTER_MODAL,
    OPEN_FOCUS_CATEGORIES_PROJECT_MODAL,
    OPEN_FOCUS_CATEGORIES_COPY_MODAL,
    OPEN_SALES_SETTINGS_MODAL,
    OPEN_PLAN_COMMENT_MODAL,
} from 'constants/actionTypes';

export const closeModal = createAction(CLOSE_MODAL);
export const hideModal = createAction(HIDE_MODAL);
export const showModal = createAction(SHOW_MODAL);

export const openProductModal = createAction(OPEN_PRODUCT_MODAL);
export const openProductCategoryModal = createAction(OPEN_PRODUCT_CATEGORY_MODAL);
export const openProductGroupModal = createAction(OPEN_PRODUCT_GROUP_MODAL);
export const openProductDepartmentModal = createAction(OPEN_PRODUCT_DEPARTMENT_MODAL);
export const openShopModal = createAction(OPEN_SHOP_MODAL);
export const openNetworkModal = createAction(OPEN_NETWORK_MODAL);
export const openClientModal = createAction(OPEN_CLIENT_MODAL);
export const openMarketingCoordinatorModal = createAction(OPEN_MARKETING_COORDINATOR_MODAL);
export const openSupervisorModal = createAction(OPEN_SUPERVISOR_MODAL);
export const openPromoterModal = createAction(OPEN_PROMOTER_MODAL);
export const openSaleEditModal = createAction(OPEN_SALE_EDIT_MODAL);
export const openSalesProductModal = createAction(OPEN_SALES_PRODUCT_MODAL);
export const openCheckinModal = createAction(OPEN_CHECKIN_MODAL);
export const openCityModal = createAction(OPEN_CITY_MODAL);
export const openRegionModal = createAction(OPEN_REGION_MODAL);
export const openCoordinatesLoader = createAction(OPEN_COORDINATES_LOADER);
export const openTimetableModal = createAction(OPEN_TIMETABLE_MODAL);
export const openTimetableSyncModal = createAction(OPEN_TIMETABLE_SYNC_MODAL);
export const openTimetablePersonShopsModal = createAction(OPEN_TIMETABLE_PERSON_SHOPS_MODAL);
export const openNewsModal = createAction(OPEN_NEWS_MODAL);
export const openDocumentModal = createAction(OPEN_DOCUMENT_MODAL);
export const openDocumentAttachModal = createAction(OPEN_DOCUMENT_ATTACH_MODAL);
export const openPromoterSupervisorBindModal = createAction(OPEN_PROMOTER_SUPERVISOR_BIND_MODAL);
export const openShopSupervisorBindModal = createAction(OPEN_SHOP_SUPERVISOR_BIND_MODAL);
export const openShopPromoterBindModal = createAction(OPEN_SHOP_PROMOTER_BIND_MODAL);
export const openDismissalModal = createAction(OPEN_DISMISSAL_MODAL);
export const openUnbindModal = createAction(OPEN_UNBIND_MODAL);
export const openPreviewModal = createAction(OPEN_PREVIEW_MODAL);
export const openImportModal = createAction(OPEN_IMPORT_MODAL);
export const openCropImageModal = createAction(OPEN_CROP_IMAGE_MODAL);
export const openShopMapModal = createAction(OPEN_SHOP_MAP_MODAL);
export const openSupervisorShopBindModal = createAction(OPEN_SUPERVISOR_SHOP_BIND_MODAL);
export const openPromoterShopBindModal = createAction(OPEN_PROMOTER_SHOP_BIND_MODAL);
export const openBrandModal = createAction(OPEN_BRAND_MODAL);
export const openMapModal = createAction(OPEN_MAP_MODAL);
export const openAdminModal = createAction(OPEN_ADMIN_MODAL);
export const openGradeModal = createAction(OPEN_GRADE_MODAL);
export const openPlanPromoterModal = createAction(OPEN_PLAN_PROMOTER_MODAL);
export const openFocusCategoriesProjectModal = createAction(OPEN_FOCUS_CATEGORIES_PROJECT_MODAL);
export const openFocusCategoriesCopyModal = createAction(OPEN_FOCUS_CATEGORIES_COPY_MODAL);
export const openSalesSettingsModal = createAction(OPEN_SALES_SETTINGS_MODAL);
export const openPlanCommentModal = createAction(OPEN_PLAN_COMMENT_MODAL);
