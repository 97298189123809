import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'formik';

import SearchInput from './SearchInput';
import MobileCollapse from 'components/common/MobileCollapse';
import { isMobile } from 'constants/screen';

export const SearchField = ({ searchFieldName = 'name', placeholder }) => (
    <Field name={searchFieldName} component={SearchInput} placeholder={placeholder} />
);

export default function BaseSearchFilter(props) {
    const additionalControls = props.additionalControls || null;
    const {
        disableSearch,
        disableMobileCollapse,
        controls,
        searchSpan = 4,
        controlsSpan = 22,
        children,
        searchFieldName,
        searchFieldPlaceholder,
    } = props;

    const filters = [
        (!isMobile() || disableMobileCollapse) && (
            <div key='additionalControls' className='filters-additional'>
                {additionalControls}
            </div>
        ),
        <Row key='header'>
            <Col sm={controls || additionalControls ? controlsSpan : 24} xs={24}>
                <Row className='filters-row' gutter={5}>
                    { !disableSearch && (
                        <Col sm={searchSpan} xs={24}>
                            <SearchField searchFieldName={searchFieldName} placeholder={searchFieldPlaceholder} />
                        </Col>
                    )}
                    { children || null }
                </Row>
            </Col>
            { controls || null }
        </Row>
    ];

    if (!disableMobileCollapse) {
        const header = (
            <div>
                Показать фильтры
                { additionalControls }
            </div>
        );

        return (
            <MobileCollapse header={header}>
                { filters }
            </MobileCollapse>
        );
    }

    return filters;
}
