import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './scenes/Login';
import Recovery from './scenes/Recovery';
import Reset from './scenes/Reset';
import UserRoute from 'components/common/UserRoute';
import { noPromoter, PROMOTER, ADMIN, CLIENT, MARKETING_COORDINATOR } from 'constants/roles';

import Main from './scenes/main/Main';

import Shops from './scenes/shops/lists/Shops';
import ShopCard from './scenes/shops/card/ShopCard';
import Networks from './scenes/shops/lists/Networks';
import Products from './scenes/products/Products';
import Categories from './scenes/products/Categories';
import Groups from './scenes/products/Groups';
import Departments from './scenes/products/Departments';
import Users from './scenes/users/Users';
import Sales from './scenes/sales/Sales';
import SalesForm from './scenes/sales/SalesForm';
import Checkin from './scenes/checkins/Checkin';
import Tasks from './scenes/tasks/lists/Tasks';
import CreateTask from './scenes/tasks/forms/CreateTask';
import TaskCard from './scenes/tasks/card/TaskCard';
import EditTask from './scenes/tasks/forms/EditTask';
import TaskReports from './scenes/tasks/lists/TaskReports';
import TaskReportCard from './scenes/tasks/card/TaskReportCard';
import AddTaskReport from './scenes/tasks/forms/AddTaskReport';
import PromoterVisits from './scenes/checkins/PromoterVisits';
import Checkins from './scenes/checkins/Checkins';
import Regions from './scenes/geography/Regions';
import Cities from './scenes/geography/Cities';
import TimeReports from './scenes/timetable/TimeReports';
import NewsList from './scenes/news/NewsList';
import News from './scenes/news/News';
import Documents from './scenes/documents/Documents';
import Profile from './scenes/Profile';
import Instructions from './scenes/instructions/Instructions';
import Plans from './scenes/plans/Plans';
import Brands from './scenes/products/Brands';
import FocusCategories from './scenes/plans/FocusCategories';
import AlreadyLogged from './scenes/AlreadyLogged';
import SalesSettings from './scenes/sales/SalesSettings';

const redirect = () => <Redirect to='/' />;

export const UserRoutes = () => (
    <Switch>
        <UserRoute path='/shops' component={Shops} access={[ADMIN, CLIENT, MARKETING_COORDINATOR]} />
        <UserRoute path='/shop/:id' component={ShopCard} access={[ADMIN, CLIENT, MARKETING_COORDINATOR]} />
        <UserRoute path='/networks' component={Networks} access={[ADMIN, CLIENT, MARKETING_COORDINATOR]} />
        <UserRoute path='/products' component={Products} access={[ADMIN, CLIENT, MARKETING_COORDINATOR]} />
        <UserRoute path='/categories' component={Categories} access={noPromoter} />
        <UserRoute path='/groups' component={Groups} access={noPromoter} />
        <UserRoute path='/departments' component={Departments} access={noPromoter} />
        <UserRoute path='/brands' component={Brands} access={noPromoter} />
        <UserRoute path='/users' component={Users} access={[ADMIN, CLIENT, MARKETING_COORDINATOR]} />
        <UserRoute path='/checkin' component={Checkin} access={[PROMOTER]} />
        <UserRoute path='/sales/add/:date' component={SalesForm} />
        <UserRoute path='/sales/add' component={SalesForm} />
        <UserRoute path='/sales' component={Sales} />
        <UserRoute path='/tasks' component={Tasks} access={noPromoter} />
        <UserRoute path='/task/create' component={CreateTask} access={noPromoter} />
        <UserRoute path='/task/:id/edit' component={EditTask} access={noPromoter} />
        <UserRoute path='/task/:id' component={TaskCard} access={noPromoter} />
        <UserRoute path='/regions' component={Regions} access={[ADMIN, CLIENT]} />
        <UserRoute path='/cities' component={Cities} access={[ADMIN, CLIENT]} />
        <UserRoute path='/visits' component={PromoterVisits} access={[PROMOTER]} />
        <UserRoute path='/checkins' exact component={Checkins} access={noPromoter} />
        <UserRoute path='/timetable' component={TimeReports} access={noPromoter} />
        <UserRoute path='/reports' component={TaskReports} access={[PROMOTER]} />
        <UserRoute path='/report/add/:id' component={AddTaskReport} access={[PROMOTER]} />
        <UserRoute path='/report/:id' component={TaskReportCard} access={[PROMOTER]} />
        <UserRoute path='/news' component={NewsList} exact />
        <UserRoute path='/news/:id' component={News} />
        <UserRoute path='/documents' component={Documents} />
        <UserRoute path='/profile' component={Profile} />
        <UserRoute path='/instructions' component={Instructions} access={[ADMIN]} />
        <UserRoute path='/plans' component={Plans} access={noPromoter} />
        <UserRoute path='/focus' component={FocusCategories} access={noPromoter} />
        <UserRoute path='/settings' component={SalesSettings} access={[ADMIN]} />

        <Route path='/reset/:token' component={AlreadyLogged} />

        <Route path='/' component={Main} exact />
        <Route component={redirect} />
    </Switch>
);

export const GuestRoutes = () => (
    <Switch>
        <Route path='/recovery' component={Recovery} />
        <Route path='/reset/:token' component={Reset} />

        <Route path='/' component={Login} exact />
        <Route component={redirect} />
    </Switch>
);
