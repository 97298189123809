
export const VALUE_ALREADY_USED = 'This value is already used.';
export const VALUE_EMPTY = 'This value should not be blank.';
export const USER_NOT_FOUND = 'User with that email not found.';
export const USER_NOT_ONE = 'Found more than 1 user with name :name';
export const USER_NAME_NOT_FOUND = 'User with name :name not found';
export const CITY_NOT_FOUND = 'City with name :name not found';
export const CITY_NOT_ONE = 'Found more than one city with name :name';
export const REGION_NOT_FOUND = 'Region with name :name not found';
export const REGION_NOT_ONE = 'Found more than one region with name :name';
export const SHOP_NOT_FOUND = 'Shop with name :name not found';
export const NETWORK_NOT_FOUND = 'Network with name :name not found';
export const NETWORK_NOT_ONE = 'Found more than one network with name :name';
export const DATE_IN_PAST = 'Date of instruction can not be in the past';
export const INVALID_EMAIL = 'This value is not a valid email address.';
export const INVALID_CREDENTIALS = 'Invalid credentials.';
export const IMPORT_ROWS = 'Can not parse rows';
export const INVALID_ROW = 'Can not parse row';
export const BAD_CREDENTIALS = 'Bad credentials';
export const PROMOTER_CHECKIN_ALREADY_EXISTED = 'Promoter checkin already exists at this date';
export const UNABLE_ADD_SALE_WITHOUT_CHECKIN = 'Unable to add sale without checkin';
export const TASK_REPORT_ALREADY_EXISTED = 'Report for task already exists at this date';
export const STATUSES_TO_IGNORE = [422];
export const INTERNAL_SERVER_ERROR = 'Internal server error';
export const WRONG_HEADERS = 'Wrong headers';
export const BIND_PROMOTER_ERROR = 'Can not add shop to promoter because shop does not have promoter\'s supervisor';

export const ERRORS = {
    [VALUE_EMPTY]: 'Не заполнено обязательное поле - :field',
    [VALUE_ALREADY_USED]: 'Этот email уже используется',
    [USER_NOT_FOUND]: 'Пользователь с таким email не существует',
    [USER_NAME_NOT_FOUND]: 'Пользователь с таким именем :name не найден',
    [USER_NOT_ONE]: 'Найдено более 1 пользователя с таким именем :name',
    [CITY_NOT_FOUND]: 'Город с таким именем :name не найден',
    [CITY_NOT_ONE]: 'Найдено более 1 города с таким именем :name',
    [REGION_NOT_FOUND]: 'Регион с таким именем :name не найден',
    [REGION_NOT_ONE]: 'Найдено более 1 региона с таким именем :name"',
    [SHOP_NOT_FOUND]: 'Магазин с таким именем :name не найден',
    [NETWORK_NOT_ONE]: 'Найдено более 1 сети с таким именем :name"',
    [NETWORK_NOT_FOUND]: 'Сеть с таким именем :name не найдена',
    [DATE_IN_PAST]: 'Дата распоряжения не должна быть раньше, чем текущая',
    [INVALID_EMAIL]: 'Некорректный формат email',
    [INVALID_ROW]: 'Не удалось обработать строку',
    [IMPORT_ROWS]: 'Не удалось обработать строки',
    [INVALID_CREDENTIALS]: 'Неверный логин или пароль. Проверьте правильность введенных данных.',
    [BAD_CREDENTIALS]: 'Неверный логин или пароль. Проверьте правильность введенных данных.',
    [PROMOTER_CHECKIN_ALREADY_EXISTED]: 'Визит уже был произведен сегодня',
    [UNABLE_ADD_SALE_WITHOUT_CHECKIN]: 'У консультанта нет чекина на этот день',
    [TASK_REPORT_ALREADY_EXISTED]: 'Отчет по данной задаче сегодня уже был внесен',
    [BIND_PROMOTER_ERROR]: 'Невозможно привязать промоутера к магазину, так как не привязан супервайзер',
    [INTERNAL_SERVER_ERROR]: 'Не удалось обработать запрос',
    [WRONG_HEADERS]: 'Некорректные столбцы в таблице импорта',

    'last sales edit date should be greater than "{month}"': 'Дата, до которой доступно редактирование продаж не должна быть ранее "{month}"',
    'settings for this month already exists': 'Настройки на этот месяц уже заданы',
    'this value is not a valid phone number.': 'Невалидный номер телефона',
    'edit sales for month "{month}" disabled since "{date}"': 'Редактирование продаж для "{month}" недоступно с "{date}"'
};

export const FIELDS = {
    name: 'Наименование',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Email',
    network: 'Сеть',
    region: 'Регион',
    city: 'Город',
    department: 'Департмент',
    productDepartment: 'Департмент',
    group: 'Группа',
    productGroup: 'Группа',
    category: 'Линейка',
    productCategory: 'Линейка',
    brand: 'Бренд',
    shop: 'Магазин',
    supervisor: 'Супервайзер',
};
